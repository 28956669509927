import React from 'react';
import Header from './Header';
const Skills = () => {
  return (
    <div className="skills" data-aos="fade">
      <Header />

      <div className="skills_description">
        <p className="skills_description-pg">
          <span style={{ fontSize: '2rem', fontWeight: '400' }}>
            Hello There 👋
          </span>
          <br />
          <br />
          I’m a Software Developer heavily invested in full-stack development,
          My expertise on underlying mechanisms of back and the front-end gives
          me the advantage to produce robust API’s, reliable code, easy to use
          UI, and most importantly very secure web applications.
          <br />
          I’m also an AWS Certified Developer Associate, working with both
          serverless and traditional development paradigms gives me the
          flexibility to adjust, save cost and develop highly scalable
          applications.
        </p>
      </div>
    </div>
  );
};

export default Skills;
