/* eslint-disable react/display-name */
import React from "react";
export default [
  {
    id: 1,
    headerColor: "#901d7d",
    textColor: "white",
    path: "payDay",
    name: "PayDay",
    projectImages: [
      "images/pos-main.png",
      "images/pos-orders.png",
      "images/pos-settings.png",
    ],
    introDescription: `
    PayDay is a modern POS system, that monitors all of your store orders,
    transactions, and employees. By accessing and using a very simple jet
    sophisticated UI, employees, and the managers can conduct their order
    of business with ease.
    `,
    buildWith: [
      "NodeJS",
      "Express",
      "Sequelize",
      "PostgreSQL",
      "AWS",
      "Javascript",
      "React",
      "Redux",
      "Sass",
      "Html",
    ],
    Description: () => {
      return (
        <p>
          PayDay is a modern POS system, that monitors all of your store orders,
          transactions, and employees. By accessing and using a very simple jet
          sophisticated UI, employees, and the managers can conduct their order
          of business with ease.
        </p>
      );
    },
    Build: ({ getLinkFunc, execModal }) => {
      return (
        <>
          <p>
            PayDay is a modern POS system, that monitors all of your store
            orders, transactions, and employees. By accessing and using a very
            simple jet sophisticated UI, employees, and the managers can conduct
            their order of business with ease.
            <br />
            <br />
            Technologies used: <br />
            Backend: NodeJS, Express, Sequelize, PostgreSQL, AWS <br />
            Frontend: Javascript, React, Redux, Sass, Html <br />
          </p>
          <div
            onClick={() => {
              execModal(true);
              getLinkFunc("https://pos-systems.herokuapp.com/");
            }}
          >
            <p className="links-style">Visit the Website</p>
          </div>
        </>
      );
    },
    sections: [
      `As per any POS system, PayDay offers the same capabilities when it comes to process and maintain the orders that already are one queue. You can edit and delete orders that are already in the queue as long the order cancellation policy is on the time frame.
             For posting and retrieving data I used  Postgress because the nature of the data is very relational.
             And to synchronize the state I used Redux on the front-end.
            `,
      `PayDay offers a settings dashboard where the owner or the authorized personnel can add products, remove products, also manage the employees.
             For fast file storage and retrieval, I used AWS S3, and for maximal security, the files are server-side encrypted with AWS KMS.
            `,
    ],
  },

  {
    id: 2,
    headerColor: "#901d7d",
    textColor: "white",
    path: "covid",
    name: "Covid USA Map",
    introDescription: `Covid19 Tracker uses the latest data to display visually the
    progression of the COVID-19 In the united states. The project contains
    two maps, the first map displays the general trend for the spread of
    the virus, the second map displays the spread of the virus by county.`,
    projectImages: [
      "images/covid-first.png",
      "images/covid-second.png",
      "images/covid-third.png",
    ],
    buildWith: ["Javascript", "D3", "AWS", "Redux", "Sass", "Html"],
    Description: () => {
      return (
        <p>
          Covid19 Tracker uses the latest data to display visually the
          progression of the COVID-19 In the united states. The project contains
          two maps, the first map displays the general trend for the spread of
          the virus, the second map displays the spread of the virus by county.
        </p>
      );
    },
    Build: () => {
      return (
        <p>
          Covid19 Tracker uses the latest data to display visually the
          progression of the COVID-19 In the united states. The project contains
          two maps, the first map displays the general trend for the spread of
          the virus, the second map displays the spread of the virus by county.
          <br />
          <br />
          <a
            className="links-style"
            href="http://covid-tracker.taulantvokshi.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Visit the Website
          </a>{" "}
        </p>
      );
    },
    sections: [
      `
        D3 gives you the advantage to build almost anything when it comes to charts maps or any other visualization, the main challenge that I faced building this project was the data mapping and update patterns, the county data file contains more than 400k data points that have to be updated any time the date changes.
        `,
      `Coloring scales for hot zones were also a challenge, but I came up with a very interesting solution, for details on the implementation  I posted the project on my GitHub account.
        Each map has zooming capabilities, so I added an info tab that when hover in any of the counties displays the exact information.
        `,
    ],
  },
  {
    id: 3,
    headerColor: "#728abd",
    path: "availablecoder",
    name: "Availablecoder",
    textColor: "white",
    projectImages: [
      "images/availablecoder-3.png",
      "images/availablecoder-2.png",
      "images/availablecoder-1.png",
    ],
    introDescription: `Availablecoder is a web application for helping software engineers finding a job.
    By simply uploading your projects to our platform and listing all the technologies that you use,
    recruiters will be able to find you by using our sophisticated filtering system.
    This way recruiters will see your work before your resume which has been proven to be more effective.
    `,
    buildWith: [
      "NodeJs",
      "Nginx",
      "Javascript",
      "AWS",
      "Redux",
      "Sass",
      "Html",
      "DigitalOcean",
      "Bash",
      "Sequelize",
      "PostgreSQL",
      "Stripe",
      "Express",
    ],

    Description: () => {
      return (
        <p>
          Availablecoder is a web application for helping software engineers
          finding a job. By simply uploading your projects to our platform and
          listing all the technologies that you use, recruiters will be able to
          find you by using our sophisticated filtering system. This way
          recruiters will see your work before your resume which has been proven
          to be more effective.
        </p>
      );
    },
    Build: () => {
      return (
        <p>
          Availablecoder is a web application for helping software engineers
          finding a job. By simply uploading your projects to our platform and
          listing all the technologies that you use, recruiters will be able to
          find you by using our sophisticated filtering system. This way
          recruiters will see your work before your resume which has been proven
          to be more effective.
          <br />
          <br />
          Technologies used: <br />
          Backend: NodeJS, Nginx, DigitalOcean, Bash, Sequelize, Express, oAuth,
          Sharp , PostgreSQL AWS Send Grid <br />
          Frontend: Javascript, React, Redux, Sass, Html SendGrid <br />
          <br />
          <br />
          <a
            className="links-style"
            href="https://availablecoder.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Visit the Website
          </a>
        </p>
      );
    },
    sections: [
      `
      The application is very secure and reliable, I used oAuth as an optional authentication mechanism.
Availablecoder runs on Digital Ocean, I used a reverse proxy (NGINX) to handle load balancing, HTTPS traffic using lets-encrypt 
certificates, and also SSL termination that will enable superfast communication between servers. 
I configured NGINX to serve static files instead of the servers, by this measure servers have less load and become very manageable. 
The system is immune to Failovers if one node is down, users are served by other nodes. For CICD I’m using Jenkins and git, so the new integration, 
testings, and deployments are just one commit away.       




            `,
      `
    Availablecoder has a beautiful, elegant UI and is fully responsive, You can access it from any device. 
    The front end is built using React with hooks, for state management I used Redux,
    For image processing, I used Sharp Library, SendGrid for password recovery, 
    AWS S3 to store all the images and non sensitive data, and also a ton of native web APIs like Blobs, ArrayBuffers, Streams, etc.
            `,
    ],
  },
  {
    id: 4,
    headerColor: "#728abd",
    path: "happyResident",
    name: "Happy Resident",
    textColor: "white",
    projectImages: [
      "images/happyresident-settings.png",
      "images/happyresident-account.png",
      "images/happyresident-assist.png",
    ],
    introDescription: `Happyresident is a building management system that gives both the
    building owners and the tenants an easy way to manage, organize and
    efficiently respond to their requests.`,
    buildWith: [
      "NodeJs",
      "Nginx",
      "Javascript",
      "AWS",
      "Redux",
      "Sass",
      "Html",
      "DigitalOcean",
      "Bash",
      "Sequelize",
      "PostgreSQL",
      "Stripe",
      "Express",
    ],

    Description: () => {
      return (
        <p>
          Happyresident is a building management system that gives both the
          building owners and the tenants an easy way to manage, organize and
          efficiently respond to their requests.
        </p>
      );
    },
    Build: () => {
      return (
        <p>
          Happyresident is a building management system that gives both the
          building owners and the tenants an easy way to manage, <br /> organize
          and efficiently respond to their requests.
          <br />
          By implementing an easy to use dashboard, residents have access to a
          variety of services, <br />
          like reporting maintenance problems, paying the rent, check their rent
          status throughout time, etc.
          <br />
          <br />
          Technologies used: <br />
          Backend: NodeJS, Nginx, DigitalOcean, Bash, Sequelize, PostgreSQL AWS
          Stripe <br />
          Frontend: Javascript, React, Redux, Sass, Html <br />
          <br />
          <br />
          <a
            className="links-style"
            href="https://happy-resident.taulantvokshi.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Visit the Website
          </a>
        </p>
      );
    },
    sections: [
      `Happy resident Report  Dashboard offers tenants an easy way to report a faulty appliance just by clicking an icon or customize a special request.
            I implemented a priority queue on the backend so each tenant will be served on a priority level in order to minimize long waitings for essential appliances reparations.
            `,
      `With this project, I wanted to show the full capabilities of production level architecture and deployment.
      The virtual machine runs on Digital Ocean, I used a reverse proxy (NGINX) to handle load balancing, https traffic using lets-encrypt certificates, and also SSL termination that will enable superfast communication between servers.
      I configured NGINX to serve static files instead of the servers,  by this measure servers have less load and become very manageable.
      The system is immune to Failovers if one node is down, users are served by other nodes.
      For CICD I’m using  Jenkins and git, so the new integration,  testings, and deployments are just one commit away.

            `,
    ],
  },
  {
    id: 5,
    headerColor: "#ffffff",
    path: "whiteboard",
    name: "Whiteboard",
    textColor: "black",
    projectImages: [
      "images/whiteboard-map.png",
      "images/whiteboard-main.png",

      "images/whiteboard-dashboard.png",
    ],
    introDescription: ` Whiteboard is a social platform web application that helps new
    developers to practice and simulate coding interviews. A developer can
    find, search or create a new event of their choice and join groups of
    people that share the same interest.`,
    buildWith: [
      "NodeJs",
      "MapBox",

      "Javascript",
      "AWS",
      "Redux",
      "Sass",
      "Html",
      "Sequelize",
      "PostgreSQL",
      "Stripe",
      "Express",
    ],
    Description: () => {
      return (
        <p>
          Whiteboard is a social platform web application that helps new
          developers to practice and simulate coding interviews. A developer can
          find, search or create a new event of their choice and join groups of
          people that share the same interest.
        </p>
      );
    },
    Build: ({ getLinkFunc, execModal }) => {
      return (
        <>
          <p>
            Whiteboard is a social platform web application that helps new
            developers to practice and simulate coding interviews.
            <br />
            A developer can find, search or create a new event of their choice
            and join groups of people that share the same interest.
            <br />
            <br />
            Technologies used: <br />
            Backend: NodeJS, Express, Sequelize, PostgreSQL and AWS <br />
            Frontend: Javascript, React, Redux, Sass, Html <br />
          </p>

          <div
            onClick={() => {
              execModal(true);
              getLinkFunc("https://whiteboard-coding.herokuapp.com");
            }}
          >
            <p className="links-style">Visit the Website</p>
          </div>
        </>
      );
    },
    sections: [
      `The main page of the whiteboard is the place where people have the ability to orient and find Events only by exploring the map each colored pointer is an event and just by checking the color of the pins you know what programing language the event represents, or by clicking, in that case, you will be redirected to that event page.
            I used the Google Geolocation API for mapping users addresses and then I implemented the results on the third-party map provider  - Mapbox.
            `,
      `
            By signing up have access to the events page where you can add an event or join one.
            You have a personalized dashboard to check which events you are attending or the ones that you organized.
            I implemented a very fast searching algorithm that can help you to search for a specific event by multiple attributes like programming language, date, name or combination of all to maximize finding results.
            `,
    ],
  },

  {
    id: 6,
    headerColor: "#f3f9fb",
    textColor: "black",
    path: "lighttext",
    name: "Lighttext",
    projectImages: [
      "images/lighttext-main.png",
      "images/lighttext-dash.png",
      "images/lighttext-charts.png",
    ],
    introDescription: `LightText (LT) is a lightning wallet that allows you to send and
    receive bitcoin payments over the lightning network. LT is an SMS
    client aimed to provide access to crypto infrastructure for
    individuals without smartphones.`,
    buildWith: [
      "NodeJs",
      "twilio",

      "Javascript",
      "Redux",
      "AWS",
      "Sass",
      "Html",
      "Sequelize",
      "PostgreSQL",
      "Express",
    ],
    Description: () => {
      return (
        <p>
          LightText (LT) is a lightning wallet that allows you to send and
          receive bitcoin payments over the lightning network. LT is an SMS
          client aimed to provide access to crypto infrastructure for
          individuals without smartphones.
        </p>
      );
    },
    Build: ({ getLinkFunc, execModal }) => {
      return (
        <>
          <p>
            LightText (LT) is a lightning wallet that allows you to send and
            receive bitcoin payments over the lightning network. <br /> LT is an
            SMS client aimed to provide access to crypto infrastructure for
            individuals without smartphones.
            <br />
            Grab your favorite Nokia phone, sign up, fund your account with
            bitcoin and begin paying friends and lightning invoices.
            <br />
            <br />
          </p>
          <div
            onClick={() => {
              execModal(true);
              getLinkFunc("https://light-text.herokuapp.com//");
            }}
          >
            <p className="links-style">Visit the Website</p>
            <a
              style={{ textDecoration: "none" }}
              href="https://youtu.be/SxjsJ4FDSzI"
              className="links-style"
            >
              Video Presantation
            </a>
          </div>
        </>
      );
    },
    sections: [
      `If you don’t have your phone near you LightText dashboard has an integrated input field for executing all the necessary commands like sending fonds or checking  the balance
        The back end is built with NodeJs and Postgres database and for the mobility part, we used Twilio API.
        You also refill your account straight from the dashboard.
        `,
      `The LightText  user page has a very useful chart for displaying the payment history sliced by each
        Payment and user.
        You can see how much you spend or receive in very detailed measures.
        And for the long run and old transactions, you can use all transaction fields.
        LightText project was part of the full-stack academy senior enrichment program.
        `,
    ],
  },
];
