import { createStore, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk'


const initalState = {
    clicked: false
}



const CLICKED = 'CLICKED'


export const isClicked = (value) => {
    return (dispatch) => {
        return dispatch({
            type: CLICKED,
            payload: value
        })
    }
}



const reducer = (state = initalState, action) => {
    switch (action.type) {
        case CLICKED:
            return { ...state, clicked: action.payload }
        default:
            return state
    }
}



const midelware = applyMiddleware(thunkMiddleware)
const store = createStore(reducer, midelware)
export default store

