import React from 'react';
import { RemoveIcon } from '../svg/icons';

const Popup = ({ execModal, goLink }) => {
  return (
    <div className="popup">
      <div onClick={() => execModal(false)} className="popup-close">
        <RemoveIcon />
      </div>
      <div className="popup-header">
        <h1>Thank you for your visit!</h1>
      </div>
      <div className="popup-text">
        <p>
          This project is hosted on heroku.com. Because hosting can be
          expensive, I’m using the free tier option for some of my personal
          projects. <br /> The only downside in the free tier is that it will
          take around 5 - 10 seconds to load the web app. This delay is not an
          indicator of the overall performance of the project.
        </p>
      </div>

      <a
        onClick={() => execModal(false)}
        className="popup-button"
        href={goLink}
        target="_blank"
        rel="noopener noreferrer"
      >
        Visit
      </a>
    </div>
  );
};

export default Popup;
