import React, { useEffect, Fragment, useState } from 'react';
import { MainPage, Navbar, Project } from './imports';
import { withRouter, Route } from 'react-router-dom';
import projectData from '../data/projects_data';

const displayProject = (data, location) => {
  const findIndex = data.findIndex((item) => '/' + item.path === location);
  return data[findIndex];
};

const App = (props) => {
  const [size, setSize] = useState(window.innerWidth);
  useEffect(() => {
    window.addEventListener('resize', () => {
      setSize(window.innerWidth);
    });
  }, [window.innerWidth]);

  // if (size < 1108) {
  //   window.location = 'http://mobile.taulantvokshi.com/';
  // } else {
  return (
    <Fragment>
      <div className="home">
        <Navbar url={props} />
        <Route exact path="/" component={MainPage} />
        <Route
          path="/:id"
          render={() => (
            <Project
              data={displayProject(projectData, props.location.pathname)}
            />
          )}
        />
      </div>
    </Fragment>
  );
  //}
};

export default withRouter(App);
