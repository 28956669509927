import React from 'react';

const ProjectSecondtSection = ({ image, section }) => {
  return (
    <div className="project_secondSection" data-aos="fade">
      <div
        className="project_secondSection-askme"
        data-aos="fade-left"
        data-aos-anchor="#trigger-left"
        data-aos-anchor-placement="top-top"
        data-aos-duration="1000"
        data-aos-delay="2000"
      >
        <a href="mailto:taulantvokshi2@gmail.com">
          <h2>Email-Me</h2>
        </a>
      </div>
      <div className="project_firstSection-image">
        <img src={image} />
      </div>

      <div className="project_firstSection-text">
        <div className="project_firstSection-text-container">
          <p id="section-size">{section}</p>
        </div>
      </div>
    </div>
  );
};

export default ProjectSecondtSection;
