import React from 'react';
import { Link } from 'react-router-dom';

const Card = ({ data }) => {
  return (
    <Link to={`/${data.path}`} className="card">
      <div className="card_image">
        <img
          className="preloaders"
          src={
            data.projectImages
              ? data.projectImages[0]
              : 'images/placeholder.jpeg'
          }
        />
      </div>
      <div className="card_information">
        <p className="card_information_project-name">{data.name}</p>
        <div className="card_information_description">
          {data.introDescription.length < 201 ? (
            <p>{data.introDescription}</p>
          ) : (
            <p>
              {data.introDescription.slice(0, 201)}
              <span style={{ color: 'crimson' }}> ...more</span>
            </p>
          )}
        </div>
        <div className="card_information_build">
          <p className="card_information_build--text">Build with:</p>
          <div className="card_information_build--tools">
            {data.buildWith.map((item, i) => {
              if (i < 3) {
                return <div key={Math.random()}>{item}</div>;
              }
            })}
            {data.buildWith.length > 3 ? (
              <p className="card_information_build--span">
                {`+${data.buildWith.length - 3} more`}
              </p>
            ) : null}
          </div>
        </div>
      </div>
    </Link>
  );
};

export default Card;
