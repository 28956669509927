import React from 'react';

const AboutMe = () => {
  return (
    <div className="about" data-aos="fade">
      <div className="about_description">
        <div className="about_description-aboutme">
          <h1>A BIT ABOUT ME!!</h1>
        </div>
        <div className="about_description-divider" />
        <p className="about_description-pg">
          After moving to the United States about 7 years ago I was destined to
          pursue what I dreamt of for a long time, being a software engineer.
          <br /> While learning and perfecting the English language, I worked in
          different industries, learned a lot, not just professionally but also
          on a personal level.
          <br />
          Joining a software development Bootcamp especially Fullstack Academy
          really gave me the confidence to not stop and move forward, and I'm
          sure that will be my only direction.
        </p>
      </div>
    </div>
  );
};

export default AboutMe;
