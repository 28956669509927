import React, { useEffect } from 'react';
import { Twitter, Linkedin, GitHub } from '../svg/icons';

const Navbar = ({ url }) => {
  const navbar = React.useRef();

  useEffect(() => {}, [url.location.pathname]);

  return (
    <nav ref={navbar} className="navbar">
      <div className="navbar-icons">
        <a
          href="https://www.linkedin.com/in/taulant-vokshi/"
          rel="noopener noreferrer"
          target="_blank"
        >
          <Linkedin color="grey" size="35" />{' '}
        </a>

        <a
          href="https://github.com/Taulantvokshi"
          rel="noopener noreferrer"
          target="_blank"
        >
          <GitHub color="grey" size="35" />
        </a>

        <a href="images/resume.pdf" download className="resume_button">
          <p>My Resume!</p>
        </a>
      </div>
    </nav>
  );
};

export default Navbar;
