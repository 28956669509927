import React, { useState } from 'react';

import {
  NodeJS,
  Git,
  Express,
  AuthO,
  Python,
  SocketIo,
  Sass,
  ReactJsx,
  HTML5,
  Javascript,
  Css,
  Redux,
  PostgreSql,
  Sequelize,
  WebPack,
  AWS,
  D3,
  DownIcon,
} from '../svg/icons';
const icons = [
  Javascript,
  Python,
  NodeJS,
  D3,
  AWS,
  PostgreSql,
  Sequelize,
  ReactJsx,
  Redux,
  Git,
  AuthO,
  SocketIo,
  Sass,
  HTML5,
  Css,
  WebPack,
];
const names = [
  'Javascript',
  'Python',
  'NodeJS',
  'd3',
  'AWS',
  'PostgreSql',
  'Sequelize',
  'React',
  'Redux',
  'Git',
  'AuthO',
  'SocketIo',
  'Sass',
  'HTML5',
  'Css',
  'WebPack',
];
const MobileSkills = () => {
  const [skillsClicked, setSkillsClicked] = useState('false');

  const buttonClick = () => {
    setSkillsClicked((prew) => !prew);
  };
  return (
    <div className="mobile-skills">
      <div onClick={buttonClick} className="mobile-skills_button">
        <p>Skills</p>
        <DownIcon color="white" />
      </div>
      <div
        style={{
          height: skillsClicked ? '0' : '34rem',
          visibility: skillsClicked ? 'hidden' : 'visible',
        }}
        className="mobile-skills_skillsTab"
      >
        {icons.map((Icon, i) => {
          return (
            <div key={i} className="mobile-skills_skillsTab-icons">
              <Icon />
              <p>{names[i]}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MobileSkills;
