import React, { useState } from 'react';

import { Arrow } from '../../svg/icons';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { isClicked } from '../../Redux/index';
import { Modal, Popup } from '../imports';
const ProjectFirstSection = ({ image, name, Build, clicked }) => {
  const [modalStatus, setClicked] = useState(false);
  const [getLink, setLink] = useState(false);
  const execModal = (bool) => {
    setClicked(bool);
  };
  const getLinkFunc = (value) => {
    setLink(value);
  };

  return (
    <div className="project_firstSection" data-aos="fade" id="trigger-left">
      <Modal isOpen={modalStatus}>
        <Popup goLink={getLink} execModal={execModal} />
      </Modal>
      <div className="projects_descriptionCircle">
        {/* <div className='projects_descriptionCircle-dot'></div> */}
        <Link to="/" onClick={() => clicked(true)}>
          <Arrow size="40px" />
        </Link>

        {/* <h2 className='projects_descriptionCircle-text' >Projects</h2> */}
      </div>

      <div className="projects_descriptionText">
        <div className="projects_descriptionText-dot" />
        <h1 className="projects_descriptionText-text">{name}</h1>
      </div>
      <div className="project_firstSection-image">
        <img src={image} />
      </div>

      <div className="project_firstSection-text">
        <div className="project_firstSection-text-container">
          <Build getLinkFunc={getLinkFunc} execModal={execModal} />
        </div>
      </div>
    </div>
  );
};

const dispatchState = (dispatch) => {
  return { clicked: (value) => dispatch(isClicked(value)) };
};

export default connect(null, dispatchState)(ProjectFirstSection);
