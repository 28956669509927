import React from 'react';
import Card from './PeojectCard';

import projectData from '../data/projects_data';

const Projects = () => {
  return (
    <div className="projects" data-aos="fade">
      <div className="projects_descriptionProject">
        <div className="projects_descriptionProject-dot" />
        <h1 className="projects_descriptionProject-text">MY WORK</h1>
      </div>
      {projectData.map((project) => {
        return <Card key={Math.random()} data={project} />;
      })}
    </div>
  );
};

export default Projects;
