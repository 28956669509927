import React from 'react';

const Header = () => {
  return (
    <div className="author">
      <div className="author-image">
        <img src="images/1000.png" />
      </div>
      <div className="author-info">
        <h1 className="author-info_name">TAULANT VOKSHI</h1>
        <p className="author-info_opac">Software developer</p>
      </div>
    </div>
  );
};

export default Header;
