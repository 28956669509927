import React from 'react';
import {
  NodeJS,
  Git,
  AuthO,
  Python,
  SocketIo,
  Sass,
  ReactJsx,
  HTML5,
  Javascript,
  Css,
  Redux,
  PostgreSql,
  Sequelize,
  WebPack,
  AWS,
  D3,
} from '../svg/icons';
const icons = [
  Javascript,
  Python,
  NodeJS,
  D3,
  AWS,
  PostgreSql,
  Sequelize,
  ReactJsx,
  Redux,
  Git,
  AuthO,
  SocketIo,
  Sass,
  HTML5,
  Css,
  WebPack,
];

const names = [
  'Javascript',
  'Python',
  'NodeJS',
  'd3',
  'AWS',
  'PostgreSql',
  'Sequelize',
  'React',
  'Redux',
  'Git',
  'AuthO',
  'SocketIo',
  'Sass',
  'HTML5',
  'Css',
  'WebPack',
];

const Icons = () => {
  return (
    <div className="hidden" data-aos="fade">
      <div className="hidden_icons">
        {icons.map((Icon, i) => {
          return (
            <div key={Math.random()} className="hidden_icons-dectription">
              <Icon />
              <p>{names[i]}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Icons;
