import React, { useEffect } from 'react';
import {
  ProjectFirstSection,
  ProjectSecondtSection,
  ProjectLastSection,
} from './imports';
import AOS from 'aos';
import 'aos/dist/aos.css';
const Project = (props) => {
  useEffect(() => {
    AOS.init({ duration: 2000 });
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="projectDetails">
      <ProjectFirstSection
        image={props.data.projectImages[0]}
        name={props.data.name}
        Build={props.data.Build}
      />
      <ProjectSecondtSection
        image={props.data.projectImages[1]}
        section={props.data.sections[0]}
      />
      <ProjectLastSection
        image={props.data.projectImages[2]}
        section={props.data.sections[1]}
      />
    </div>
  );
};

export default Project;
