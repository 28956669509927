//ROOT Component
export { default as App } from './App';
export { default as MainPage } from './MainPage';

//MainComponents
export { default as Skills } from './Skills';
export { default as About } from './About';
export { default as Projects } from './Projects';
export { default as Header } from './Header';
export { default as Project } from './Project_details';
export { default as MobileSkills } from './Mobile-skills';
export { default as Icons } from './Icons';

//InnerComponents
export { default as Navbar } from './Navbar';

//Modal
export { default as Modal } from './Modal';
export { default as Popup } from './Pop-up';

//project
export { default as ProjectHeader } from './project-components/Project_header';
export { default as ProjectFirstSection } from './project-components/Project_firstSection';
export { default as ProjectSecondtSection } from './project-components/Project_secondSection';
export { default as ProjectLastSection } from './project-components/Project_lastSection';
