import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Skills, Projects, About, MobileSkills, Icons } from './imports';
import AOS from 'aos';
import 'aos/dist/aos.css';
const MainPage = ({ clicked }) => {
  useEffect(() => {
    AOS.init({ duration: 1500 });
    if (clicked) {
      window.scrollTo(0, 700);
    } else {
      window.scrollTo(0, 0);
    }
  }, []);

  return (
    <div className="main-page">
      <Skills />
      {window.innerWidth < 500 ? <MobileSkills /> : ''}
      <Projects />
      <Icons />
      <About />
    </div>
  );
};
const dispatchProps = (store) => {
  return { clicked: store.clicked };
};
export default connect(dispatchProps)(MainPage);
