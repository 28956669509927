import React from 'react';

const ProjectLastSection = ({ image, section }) => {
  return (
    <div className="project_lastSection" data-aos="fade">
      <div className="project_firstSection-image">
        <img src={image} />
      </div>

      <div className="project_firstSection-text">
        <div className="project_firstSection-text-container">
          <p id="section-size">{section}</p>
        </div>
      </div>
    </div>
  );
};

export default ProjectLastSection;
