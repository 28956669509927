import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './components/imports'
import { Provider } from 'react-redux'
// import { Router, BrowserRouter } from 'react-router-dom'
import { HashRouter } from 'react-router-dom'
import store from './Redux'
import history from './history'
ReactDOM.render(
    <Provider store={store}>
        <HashRouter history={history} >
            <App />
        </HashRouter>
    </Provider >,
    document.getElementById('root') // make sure this is the same as the id of the div in your index.html
);  